/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable operator-linebreak */
import Utility from './Utility';
import { ls } from './LocalStorage';

class Authorization {
  static ROLE_CONFIG_MANAGER = 'CONFIG MANAGER';

  static ROLE_ADMIN = 'ROOTADMIN';

  constructor() {
    this.authUser = null;
    this.authUserId = null;
    this.authRole = null;
    this.refreshRate = null;
  }

  /**
   * set auth user details to class property
   *
   * @return void
   */
  setAuthUser() {
    this.authUser = JSON.parse(ls.getItem('auth_user'));
  }

  /**
   * set auth user details to class property
   *
   * @return void
   */
  getLocalRefreshRate() {
    this.refreshRate = JSON.parse(ls.getItem('refreshRate'));
    if (this.refreshRate) {
      return this.refreshRate * 1000;
    }

    return undefined;
  }

  /**
   * check is active user is logged in
   *
   * @return boolean
   */
  isLoggedIn() {
    return typeof ls.getItem('auth_user') === 'string';
    //
  }

  /**
   * Once user is logged in, redirect the user to view permission page
   * By default will redirect to 'dashboard' page.
   * If user does not have permission to access dashboard page,
   * find the view permission page from the his permission object
   * and redirect the user to respective path.
   *
   * @param {*} props
   */
  redirectAfterLogin() {
    ls.setItem('refreshRate', 60);
    // Adding this as we are commenting Light/Theme Toggle for Alpha
    // Remove this if default dark theme is not required
    // ls.setItem('theme', 'dark');

    const isAdminRole = this.getAuthUserRole();
    // const userId = this.getAuthUserId();
    if (isAdminRole === 'ROOTADMIN') {
      window.location.assign(`${window.location.origin}/profile`);
      // For UAT Testing purposes only
      // }
      // else if (userId === 4124872) {
      //   window.location.assign(`${window.location.origin}/track-trace`);
      // } else if (ls.getItem('uiType') === 'beta') {
      //   window.location.assign(`${window.location.origin}/track-trace`);
      // }
    } else if (isAdminRole === 'SUPERADMIN') {
      window.location.assign(`${window.location.origin}/profile`);
    } else if (isAdminRole === 'CSSUPPORT') {
      window.location.assign(`${window.location.origin}/cssupport`);
    } else {
      window.location.assign(`${window.location.origin}/track-trace`);
    }

    ls.setItem('showDTCPopup', true);
  }

  /**
   * check user is having the expected role
   *
   * @param role
   * @return boolean
   */
  isUserRole(role) {
    const user = this.getAuthUser();

    return (
      Utility.isObject(user) &&
      Utility.isObject(user.roles) &&
      user.roles[0] === role
    );
  }

  /**
   * check logged user is admin
   *
   * @return boolean
   */
  isAdmin() {
    return this.isUserRole(Authorization.ROLE_ADMIN);
  }

  /**
   * check logged user is config manager
   *
   * @return boolean
   */
  isConfigManager() {
    return this.isUserRole(Authorization.ROLE_CONFIG_MANAGER);
  }

  /**
   * get logged in user details
   *
   * @return boolean
   */
  getAuthUser() {
    if (this.isLoggedIn() && !this.authUser) {
      this.setAuthUser();
    }

    return this.authUser;
  }

  /**
   * get auth user identifier
   *
   * @return int
   */
  getAuthUserId() {
    const user = this.getAuthUser();

    return Utility.isObject(user) && user.id ? user.id : 0;
  }

  getBetaAccess() {
    const user = this.getAuthUser();

    return Utility.isObject(user) && 'betaAccess' in user
      ? JSON.parse(user.betaAccess)
      : false;
  }

  getAlphaAccess() {
    const user = this.getAuthUser();

    return Utility.isObject(user) && 'betaAccess' in user
      ? JSON.parse(user.alphaAccess)
      : false;
  }

  /**
   * get auth user role
   *
   * @return string
   */
  getAuthUserRole() {
    const user = this.getAuthUser();

    return Utility.isObject(user) && user.roles?.length ? user.roles[0] : '';
  }

  /**
   * Get authentication access token
   *
   * @return string
   */
  getAccessToken() {
    const authUser = this.getAuthUser();

    return authUser && Utility.isString(authUser.token) ? authUser.token : null;
  }

  /**
   * login the user by setting it in local storage
   *
   * @return boolean
   */
  login(data) {
    if (typeof Storage !== 'undefined') {
      ls.removeItem('auth_user');

      ls.setItem('auth_user', JSON.stringify(data));
      // ls.setItem('betaUser', 'betaAccess' in data ? data.betaAccess : false);
      // ls.setItem('alphaUser', 'alphaAccess' in data ? data.alphaAccess : false);

      // Setting default uiType in ls wrt alpha and beta received in login API
      // if ('betaAccess' in data && 'alphaAccess' in data) {
      //   data.betaAccess && !data.alphaAccess
      //     ? ls.setItem('uiType', 'beta')
      //     : ls.setItem('uiType', 'alpha');
      // }
    } else {
      console.error('local storage is not supported');
    }
  }

  /**
   * get logged in user details
   *
   * @return boolean
   */
  logout() {
    // clear URL stored in localStorage
    if (typeof Storage !== 'undefined') {
      ls.removeItem('auth_user');
      ls.removeItem('userProfile');
      ls.removeItem('refreshRate');
      ls.removeItem('showDTCPopup');
      ls.removeItem('guided_demo');
      ls.removeItem('firstName');
      // ls.removeItem('betaUser');
      // ls.removeItem('alphaUser');
      ls.removeItem('theme');
      ls.removeItem('id_token');
      ls.removeItem('refresh_token');
      ls.removeItem('userName');
      ls.removeItem('expiryAcknowledged');
      ls.removeItem('expires_on');
      ls.removeItem('loginTickerAcknowledged');
      sessionStorage.clear();
      // this.emptyCache();
      // this.clearCookies();
      window.location.assign(`${window.location.origin}/`);

      this.authUser = null;
    } else {
      console.error('local storage is not supported');
    }
  }

  emptyCache() {
    if ('caches' in window) {
      caches.keys().then(names => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        });
      });

      // // Makes sure the page reloads. Changes are only visible after you refresh.
      // window.location.reload(true);
    }
  }

  clearCookies() {
    const cookies = document.cookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }
}

export default new Authorization();
