/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import Modal from 'react-responsive-modal';
import LogoWhite from '../../assets/images/tk_logo_white.png';
import LogoBlue from '../../assets/images/tk_logo_blue.png';
import themeSelected from '../Common/Context';

function TermsPopup(props) {
  const { open, onClose } = props;
  const themeType = useContext(themeSelected);

  return (
    <>
      <Modal open={open} onClose={() => onClose()}>
        <div
          className={
            themeType.theme === 'dark'
              ? 'privacyContainer'
              : 'privacyContainer lightTheme'
          }
        >
          <div className="footer-pop-container">
            <div className="footer-pop-left">
              <div className="logo footer-pop-logo">
                <img
                  src={themeType.theme === 'dark' ? LogoWhite : LogoBlue}
                  alt="logo"
                />
              </div>
              <p className="terms-header">TERMS AND CONDITIONS</p>
              <p className="policy-level-header"> 1. Scope</p>
              <p className="policy-subheader">
                {' '}
                Daimler India Commercial Vehicles Pvt. Ltd., having its
                Registered office at SIPCOT Industrial Growth Centre, Oragadam,
                Mathur Post, Sriperumbudur Taluk, Kancheepuram District –
                602105, Tamil Nadu, Republic of India hereinafter referred to as
                “Company”, shall provide hardware and services as defined in the
                terms and conditions herein under, to the Customer. “Customer or
                Subscriber" shall mean any person, company, proprietorship or
                partnership firm or any other association of persons or entity
                of whatever nature, which has subscribed for the telematics
                services offered by the Company and include Subscriber’s heirs,
                executors, administrators, successors and permitted assigns.
                “Service(s)" shall mean vehicle tracking services and vehicle
                related data which can be offered by the Company but does not
                include any telecommunication services provided through any SIM.
                "Equipment" shall mean any compatible telematics device
                necessary for transmitting vehicle location and other vehicle
                related data to the central base station, “SIM” shall mean any
                compatible SIM card pre-fitted or provided along with the
                Equipment by DICV for the purpose of enabling the Equipment to
                connect with a network and transmit and receive data required to
                use the Services.
              </p>
              <p className="policy-level-header">
                {' '}
                2. Provision for Services{' '}
              </p>{' '}
              <p className="policy-subheader">
                (I) Services shall be available at all times subject to Force
                Majeure conditions including but not limited to act of God,
                fires, strikes, embargoes, war, insurrection, riots and other
                causes beyond the reasonable control of the Company including
                atmospheric/ geographical hindrances. (II) Company may
                temporarily suspend whole or part of the Services at any time
                without notice, if the infrastructure or network fails or
                requires modifications or maintenance.
              </p>
              <p className="policy-level-header">
                3. Rights and Liabilities of the Company{' '}
              </p>{' '}
              <p className="policy-subheader">
                {' '}
                (I) The Company shall be responsible for providing the services
                to the Subscriber (II) In any event, the maximum overall
                liability of the Company in contract, tort or otherwise, shall
                be to refund the amount of security deposit (if any), after
                adjusting the charges due from the Subscriber. Without
                prejudice, in no event shall the Company, its officers,
                employees, directors, its representatives and assigns be liable
                for any direct, indirect or consequential damages, costs,
                expenses or losses of whatsoever nature, including but not
                limited to, loss of profit or loss of business resulting from
                use or inability to use the Service, or the availability,
                completeness or correctness or loss of data.. (III) The Company
                shall not be responsible for any act of commission or omission
                of any third party / supplier / manufacturer / agency / Company
                offering any privilege or benefit to the Subscriber without
                express permission or authority of the Company. (IV) Subscribers
                shall without any objection accept the Equipment allotted by the
                Company. The discretion with regard to the choice of Equipment
                to be allotted shall solely and absolutely vest with the
                Company. The SIM which forms the part of the Equipment shall be
                supplied by the Company and shall remain as the exclusive
                property of the Subscriber. (V) The Company is entitled after
                prior intimation and confirmation to change, vary, add or
                withdraw any Service at any point in its sole discretion. (VI)
                The Company shall reserve the right to transfer or assign and /
                or delegate all or part(s) of its obligations, rights and /or
                duties under these terms and conditions to any party. Such
                transfer / assignment shall release the Company from all
                liabilities under these terms and conditions. (VII) The Company
                may be required to disclose any information or particulars
                pertaining to the Subscriber to any Authority, statutory or
                otherwise, including but not limited to, any security agencies
                and reserves the right to comply with the directions of such
                authorities at its discretion and without intimating the
                Subscriber.(VIII) The Company reserves the right to seek /
                verify particulars provided by the Subscriber to the Company, in
                any manner without notice or intimation. (IX) The Company's
                Equipment is non-transferable unless and until the Subscriber
                seeks prior permission from the Company for intended transfer
                and comply with the formalities of the Company. (X) The Company
                reserves the right to provide the Services through its agents
                and dealers. (XI) The Company shall unless the Subscriber
                indicates otherwise send promotional material to Subscriber via
                secured electronic media, which or otherwise include text
                messages, multimedia content, pictures, emails and voice. (XII)
                The Company monitors all connected trucks real time and collects
                vehicle performance data which is stored in the cloud which is
                owned by the Company and managed by Daimler Group Companies. The
                Company reserves the right to access anytime any/all the vehicle
                and customer account related data stored in the Equipment,
                anywhere in the network or in its infrastructure. (XIII) The
                Company for the benefit of Customers shall periodically or as
                and when required remotely update Firmware over The Air (FoTA)
                and correct any bugs or other irregularities if any without any
                intimation to the Customer.
              </p>
              <p className="policy-level-header">
                {' '}
                4. Warranties, Rights and Liabilities of Subscriber{' '}
              </p>{' '}
              <p className="policy-subheader">
                (I) Subscriber is not entitled to transfer or assign its
                obligations and liabilities under the terms and conditions to
                any other party under any circumstances without prior permission
                of the Company. (II) Any transfer affected in contravention of
                the express terms contained herein shall not absolve the
                Subscriber of his obligations/liabilities towards the Company
                for charges levied against the Subscriber. (III) Subscriber
                shall not use the Service or Equipment or SIM for any improper,
                immoral, unlawful or abusive purpose or for sending obscene,
                indecent, threatening, harassing, and unsolicited messages to
                create any damage to the Company or any other person whomsoever.
                Any such misuse shall under no circumstance be attributed to the
                Company and the Subscriber shall be solely responsible for such
                acts. Subscriber hereby agrees to indemnify and hold harmless
                the Company and its agent and from all suits, costs, damages or
                claims of any kind arising out of any act or omission or misuse
                of the Service by the Subscriber or any other person with or
                without the consent of the Subscriber . (IV) Subscriber shall
                pay all the cost of collection of dues, legal expenses etc. with
                interest should it become necessary to refer the matter to
                collection agency or to legal recourse to enforce payment. (V)
                For safety of the vehicle, Cargo, Luggage, Driver and Other
                Persons/Passengers in the vehicle, the Company strongly
                recommends that operation of Equipment by the driver or any
                other person for voice, text communication be performed when the
                vehicle is not in motion and the Subscriber shall advise
                accordingly the driver or other persons responsible for
                operating the Equipment.
              </p>
            </div>

            <div className="footer-pop-right">
              <p className="policy-level-header">
                {' '}
                5. Suspension / Termination / Discontinuation of Services{' '}
              </p>
              <p className="policy-subheader">
                {' '}
                (I) Service quality functionality availability and/or
                reliability may be affected and/or the Company is entitled to,
                without any liability to refuse, limit, suspend, vary,
                disconnect and/or interrupt the Services in whole or in part, at
                any time in its sole discretion with respect to one/all
                Subscribers without any notice for any reason and/or due to
                various factors including but not limited to: Changes in the
                law, rules, regulations of order, directions, notifications etc.
                by the authorities, Transmission limitation caused by physical
                obstruction, geographic, topography, hydrological, metrological
                and other causes of radio interference or faults or disruption
                in telecommunication services to which Equipment is connected,
                Force Majeure circumstance. (II) In case of misuse of Services
                or breach of any terms and conditions by the Subscriber or use
                of Services in such manner that it violates any laws or
                adversely affects or interferes with the Company in providing
                Service, the Company shall give notice of five (5) days to the
                Subscriber to remedy the breach. In case the Subscriber fails to
                remedy the breach within the notice period, the Company is
                entitled to disconnect the Services without any further notice.
                (III) The Company at its sole discretion may suspend /
                discontinue the services by giving reasonable notice to
                Subscriber in such form as may be decided by the Company.
              </p>
              <p className="policy-level-header">
                6. Obligations of the Subscriber:
              </p>{' '}
              <p className="policy-subheader">
                Subscriber hereby agrees (I) The Subscriber hereby confirms that
                he will be bound by the same terms and conditions as mentioned
                herein for Services provided by the Company (II) The Subscriber
                hereby confirms that he will be bound by the same terms and
                conditions in addition to special terms and conditions as
                mentioned herein for the Equipment supplied by the Company (III)
                Subscriber shall be permitted to avail the Services only by
                means of the Equipment approved for use by the Company for the
                purpose of transmitting location information and vehicle related
                data (IV) Only Subscriber’s authorized personnel shall be
                permitted to access the Services through internet or Customer
                Support Center or any other mechanism to be decided by the
                Company from time to time. Any attempt to access the Services by
                any/all personnel other than the authorized personnel of the
                Subscriber shall be deemed to be a breach of the agreement and
                is liable to criminal proceedings. Any attempt to breach
                security systems by the Subscribers shall be liable for criminal
                proceedings. (IV) The Subscriber shall pay the prescribed
                charges periodically for the Services as per plans offered by
                the Company. In case of default of payment, the Service shall be
                terminated without any notice. However, the Company shall
                continue collecting data unless informed otherwise by the
                Subscriber in writing as per the procedure mentioned by the
                Company(V) In case the Subscriber Equipment is lost, misplaced
                or stolen, the Subscriber shall inform the dealer immediately.
                The Company takes no responsibility of the misuse of the lost,
                misplaced or stolen Subscriber Equipment or any damage with the
                same. A fresh Equipment shall be activated after receiving the
                application with due formalities and on acceptance of the terms
                and conditions laid thereof, from the Subscriber along with a
                copy of FIR lodged for the loss, misplaced or stolen Equipment.
                Equipment tampering of any sort will render void of the
                warranty. (VI) In case the Subscriber desires to withdraw the
                Services and wants the Company to refrain from collecting data,
                the same to be communicated by the Customer in writing as per
                the format prescribed by the Company.
              </p>
              <p className="policy-level-header">7.Correspondence</p>{' '}
              <p className="policy-subheader">
                {' '}
                Any notification required to be given in writing by fax, email
                or registered post with a/d to the recipient at his/her or its
                address specified in these terms and conditions / Customer
                Application Form unless such recipient has previously notified
                the other party in writing of a change of address.
              </p>
              <p className="policy-level-header">8. Validity</p>{' '}
              <p className="policy-subheader">
                (I) The validity, construction and performance of terms and
                conditions herein shall be governed and interpreted in
                accordance with the laws of India and the Company and the
                Subscriber agree to submit to the exclusive jurisdiction of the
                Chennai courts. (II) Should any provision of therein be or
                become ineffective or beheld to be invalid, this shall not
                affect the validity of the remaining provision hereof. (III)
                Unless or otherwise expressly stated, the terms defined in the
                terms and conditions, include the plural as well as the
                singular, masculine as well as feminine and natural person is
                deemed to include any Company, partnership, joint venture,
                association, corporation or any other body of government agency.
                (IV) In case two or more persons constitute the Subscriber,
                their liability shall be joint and several. (V) These terms and
                conditions read with Customer Application Form and the special
                tariff terms and conditions if any, is the complete
                understanding between the Company and the Subscriber hereto and
                it supersedes all prior understanding whether oral or written
                and all representations and other communications between the
                Company and the Subscriber hereto in case of any discrepancies
                between these terms and conditions and tariff, the later shall
                prevail. (VI) These terms and conditions are subject to Indian
                Telegraph Act of 1885, the rules and regulations framed there
                under and any statutory modifications or reenactment for the
                time being in force and also subject to the statute, rules and
                regulations and guidelines as may be enforced from time to time.
                (VII) Unless / otherwise specially provided by the Company, no
                delay or failure to provide Services or to exercise any of its
                rights shall impair such rights or shall be construed to be a
                waiver by the Company.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default TermsPopup;
