/* eslint-disable import/prefer-default-export */
import moment from 'moment';

// for alert notification screen mapping
export const alertMapping = {
  GEOFENCE: 'Geofence',
  GEOFENCEIN: 'Geofence',
  GEOFENCEOUT: 'Geofence',
  GEOFENCEINOUT: 'Geofence',
  STOPPAGE: 'Vehicle Stoppage',
  IDLING: 'Vehicle Idling',
  FUELFILL: 'Fuel Fill',
  ENGINEOVERRUN: 'Engine Overrun',
  NEUTRALDRIVING: 'Neutral Driving',
  FUELDROP: 'Fuel Drop',
  ADBLUEFAULT: 'Adblue Fault',
  ADBLUEZONE: 'Adblue Zone',
  ADBLUELOW: 'Adblue Low',
  OVERSPEED: 'Overspeed',
};

export const reportType = [
  { id: 'alert', name: 'Alert Wise' },
  { id: 'vehicle', name: 'Vehicle Wise' },
];

// setting screen data
export const ALERTS_DATA = [
  {
    groupName: 'Alerts For Productivity',
    alerts: [
      {
        title: 'Geofence Alert',
        id: 'geofenceAlert',
        description:
          'Alert to track the entry and exit of vehicle inside a predefined geofenced area.',
        label: 'Trigger alert if a vehicle passed through a Geo Fence',
        options: [],
        isMobile: true,
        valueIn: '',
      },
      {
        title: 'Vehicle Stoppage Alert',
        id: 'stoppageAlert',
        description:
          'This alert is used to track location and duration of vehicle stops - user defined limit.',
        label: 'Trigger alert if stoppage duration is Greater than',
        options: [],
        isMobile: true,
        valueIn: 'mins',
      },
      {
        title: 'Vehicle Idling Alert',
        id: 'idlingAlert',
        description:
          'Alert to track the location and duration of a vehicles idling beyond user-defined limit.',
        label: 'Trigger alert if vehicle idling time is Greater than',
        options: [],
        isMobile: true,
        valueIn: 'mins',
      },
      {
        title: 'Adblue Low Alert',
        id: 'adblueLowAlert',
        description:
          'This alert is used to track vehicle which are running low on adblue',
        label: 'Trigger alert if AdBlue level is below',
        options: [],
        isMobile: true,
        valueIn: 'ltrs',
      },
      // {
      //   title: 'Fuel Low Alert',
      //   id: 'fuelLowAlert',
      //   description:
      //     'This Alert is used to indicated the Fleet owner about the low Fuel detected for any particular vehicle in the entire fleet.',
      //   label: 'Trigger alert if fuel is below',
      //   options: [],
      //   isMobile: true,
      //   valueIn: 'ltrs',
      // },
    ],
  },
  {
    groupName: 'Alerts For Safety',
    alerts: [
      {
        title: 'Vehicle Over Speeding Alert',
        id: 'overspeedAlert',
        description:
          'Alert to track the location and vehicle speed if it exceeds user-defined limit.',
        label: 'Trigger alert if Vehicle speed is Greater than',
        options: [],
        isMobile: true,
        valueIn: 'kmph',
      },
      {
        title: 'Engine Overrun Alert',
        id: 'engineOverrunAlert',
        description:
          'Alert to track the vehicle & location where engine RPM has exceeded set limit',
        label:
          "Trigger alert if Engine RPM is with a value of 2850 if it's HDT and 3100 if it's MDT",
        options: [],
        isMobile: true,
        valueIn: '',
      },
      {
        title: 'Neutral Driving Alert',
        id: 'neutralDrivingAlert',
        description:
          'Alert to track the vehicle & location where driver was free running for user-defined duration',
        label: 'Trigger alert if gear is in Neutral for duration Greater than',
        options: [],
        isMobile: true,
        valueIn: 'seconds',
      },
    ],
  },
  {
    groupName: 'Alerts For Cost',
    alerts: [
      {
        title: 'Fuel Drop Alert',
        id: 'fuelDropAlert',
        description:
          'Alert to track the location where fuel level quantity falls below user-defined limit.',
        label: 'Trigger alert if Fuel Drop is Greater than',
        options: [],
        isMobile: true,
        valueIn: 'ltrs',
      },
      {
        title: 'Fuel Fill Alert',
        id: 'fuelFillAlert',
        description:
          'Alert to track the location where Fuel fill above user-defined limit.',
        label: 'Trigger alert if Fuel Fill is Greater than',
        options: [],
        isMobile: true,
        valueIn: 'ltrs',
      },
      // {
      //   title: 'Adblue Drop Alert',
      //   id: 'adblueDropAlert',
      //   description:
      //     'This Alert is used to indicated the Fleet owner about the Adblue drop detected for any particular vehicle in the entire fleet.',
      //   label: 'Trigger alert if alert drop is greater than',
      //   options: [],
      //   isMobile: true,
      //   valueIn: '%',
      // },
      // {
      //   title: 'Adblue Fill Alert',
      //   id: 'adblueFillAlert',
      //   description:
      //     'This Alert is used to indicated the Fleet owner about the Adblue Fill detected for any particular vehicle in the entire fleet',
      //   label: 'Trigger alert if alert fill is greater than',
      //   options: [],
      //   isMobile: true,
      //   valueIn: '%',
      // },
    ],
  },
  {
    groupName: 'Alerts For Compliance',
    alerts: [
      {
        title: 'Adblue Fault Alert',
        id: 'adblueFaultAlert',
        description:
          'Alert to track the location when there is fault in the after treatment system (ATS)',
        label: '',
        options: [],
        isMobile: true,
        valueIn: '',
      },
      {
        title: 'Adblue Zone Alert',
        id: 'adblueZoneAlert',
        description:
          'Alert to track the location and vehicle when Diesel Particulate Filter is choked and needs regeneration',
        label: 'Trigger alert if zone value is between 2 to 5',
        options: [],
        isMobile: true,
        valueIn: '',
      },
    ],
  },
];

// api default data
export const DEF_SEARCH_PARAM = {
  vehicleIds: [],
  fromTsInIST: moment(new Date())
    .subtract(6, 'hours')
    .format('YYYY-MM-DD HH:mm:ss'),
  toTsInIST: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  reportNames: [],
  groupNames: [],
};

// table headers
const StoppageTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Date',
    id: 'Date',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '180px',
  },
  {
    name: 'Stoppage threshold',
    id: 'StoppageThreshold',
    unitConversion: '(HH:MM:SS)',
  },
  {
    name: 'Stoppage duration',
    id: 'StoppageDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '160px',
  },
  {
    name: 'Geofence Name',
    id: 'GeoFenceName',
    style: {
      maxWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    sort: true,
  },
  {
    name: 'Address',
    id: 'Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
];
const AdBlueLowTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Date',
    id: 'Date',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
  },
  { name: 'Adblue reserve (ltrs)', id: 'Adblue', sort: true },
  {
    name: 'Address',
    id: 'Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
];
const AdBlueFaultTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Date',
    id: 'Date',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '200px',
  },
  {
    name: 'Adblue Fault Description',
    id: 'AdblueFaultDescription',
    minWidth: '550px',
    sort: true,
  },
  {
    name: 'Address',
    id: 'Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
];
const AdBlueZoneTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Date',
    id: 'Date',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '180px',
  },
  { name: 'Adblue Zone', id: 'AdblueZone', sort: true },
  {
    name: 'Alert Description',
    id: 'ALERT_DESC',
    minWidth: '450px',
    sort: true,
  },
  {
    name: 'Address',
    id: 'Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
];
const NeutralDriveTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Date',
    id: 'Date',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '230px',
  },
  {
    name: 'Neutral driving Duration',
    id: 'NeutralRunDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '230px',
  },
  {
    name: 'Max Vehicle Speed while Neutral',
    id: 'MaxSpeed',
    format: value => (value.includes('.') ? Number(value).toFixed(2) : value),
    sort: true,
    unitConversion: '(kmph)',
    minWidth: '280px',
  },
  {
    name: 'Distance Travelled in Neutral',
    id: 'NeutralDistanceTravel',
    sort: true,
    unitConversion: '(mtr)',
    minWidth: '230px',
  },
  {
    name: 'Address',
    id: 'Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
];
const NeutralDriveVWTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Neutral driving Duration',
    id: 'NeutralRunDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
  },
  {
    name: 'Max vehicle speed while Neutral',
    id: 'MaxVehicleSpeedWhileNeutral',
    format: value => (value.includes('.') ? Number(value).toFixed(2) : value),
    sort: true,
    unitConversion: '(kmph)',
    maxWidth: '160px',
  },
  {
    name: 'Distance Travelled in Neutral',
    id: 'DistanceTravelledInNeutral',
    sort: true,
    unitConversion: '(mtr)',
    maxWidth: '160px',
  },
];
const OverrunTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Date',
    id: 'Date',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '210px',
  },
  { name: 'Overrun RPM Threshold', id: 'OverrunRPMThreshold' },
  { name: 'Min Overrun RPM', id: 'OverrunRPM', sort: true },
  {
    name: 'Max Overrun RPM',
    id: 'MaxOverrunrpm',
    format: value => (value.includes('.') ? Number(value).toFixed(2) : value),
    sort: true,
  },
  {
    name: 'Overrun duration',
    id: 'OverrunDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '175px',
  },
  {
    name: 'Max Vehicle Speed',
    id: 'VehicleSpeed',
    sort: true,
    unitConversion: '(kmph)',
    minWidth: '150px',
  },
  {
    name: 'Address',
    id: 'Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
];
const OverrunVWTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  { name: 'Overrun RPM Threshold', id: 'OverrunRPMThreshold' },
  { name: 'Overrun count', id: 'OverrunCount', sort: true },
  {
    name: 'Max Overrun RPM',
    id: 'MaxOverunRPM',
    format: value => (value.includes('.') ? Number(value).toFixed(2) : value),
    sort: true,
  },
  {
    name: 'Overrun duration',
    id: 'OverrunDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
  },
];
const IdlingTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Date',
    id: 'Date',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '180px',
  },
  {
    name: 'Idling threshold',
    id: 'IdlingThreshold',
    unitConversion: '(HH:MM:SS)',
  },
  {
    name: 'Idling duration',
    id: 'IdlingDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '140px',
  },
  {
    name: 'Geofence Name',
    id: 'GeoFenceName',
    sort: true,
    style: {
      maxWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  {
    name: 'Address',
    id: 'Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
];
const OverSpeedTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Date',
    id: 'Date',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    maxWidth: '160px',
  },
  { name: 'Speed Limit', id: 'SpeedLimit', unitConversion: '(kmph)' },
  {
    name: 'Max speed',
    id: 'MaxSpeed',
    format: value => (value.includes('.') ? Number(value).toFixed(2) : value),
    sort: true,
    unitConversion: '(kmph)',
  },
  {
    name: 'Address',
    id: 'Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
];
const OverSpeedVWTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  { name: 'Speed Limit', id: 'SpeedLimit', unitConversion: '(kmph)' },
  { name: 'Overspeed count', id: 'Overspeedcount', sort: true },
  {
    name: 'Max Speed',
    id: 'MaxSpeed',
    format: value => (value.includes('.') ? Number(value).toFixed(2) : value),
    sort: true,
    unitConversion: '(kmph)',
    minWidth: '120px',
  },
  {
    name: 'Overspeed Distance',
    id: 'OverSpeedDistance',
    sort: true,
    unitConversion: '(mtr)',
    minWidth: '170px',
  },
  {
    name: 'Overspeed Duration',
    id: 'OverSpeedDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '170px',
  },
  {
    name: 'Duration at Max speed',
    id: 'DurationAtMaxSpeed',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '200px',
  },
];

const FuelDropTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Date',
    id: 'Date',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
  },
  { name: 'Alert Threshold', id: 'AlertThreshold', unitConversion: '(ltrs)' },
  {
    name: 'Fuel Drop Qty',
    id: 'FuelQuantity',
    sort: true,
    unitConversion: '(ltrs)',
    minWidth: '120px',
  },
  { name: 'Geofence Name', id: 'GeoFenceName', sort: true },
  {
    name: 'Address',
    id: 'Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
];

const GeofenceTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  { name: 'Geofence Name', id: 'GEOFENCE_NAME', sort: true },
  {
    name: 'Entry Speed',
    id: 'EntrySpeed',
    format: value => (value.includes('.') ? Number(value).toFixed(2) : value),
    sort: true,
    unitConversion: '(kmph)',
    minWidth: '130px',
  },
  {
    name: 'Entry Date',
    id: 'EntryDate',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '235px',
  },
  {
    name: 'Exit Speed',
    id: 'ExitSpeed',
    format: value => (value.includes('.') ? Number(value).toFixed(2) : value),
    sort: true,
    unitConversion: '(kmph)',
    minWidth: '130px',
  },
  {
    name: 'Exit Date',
    id: 'ExitDate',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '235px',
  },
  {
    name: 'Duration within Geofence',
    id: 'DurationWithinGeofence',
    unitConversion: '(HH:MM:SS)',
    sort: true,
  },
  {
    name: 'Running duration',
    id: 'RunningDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '160px',
  },
  {
    name: 'Idling duration',
    id: 'IdlingDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '160px',
  },
  {
    name: 'Stopped duration',
    id: 'STOPPAGE_DURATION',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '160px',
  },
  {
    name: 'Fuel drop',
    id: 'FUEL_DROP',
    sort: true,
    unitConversion: '(ltrs)',
  },
  {
    name: 'Entry',
    id: 'Entry_Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
  {
    name: 'Exit',
    id: 'Exit_Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
];

const FuelFillTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'Vin', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Date',
    id: 'Date',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
  },
  {
    name: 'Fuel Fill Threshold',
    id: 'fuel_fill_alert_limit',
    sort: true,
    unitConversion: '(ltrs)',
  },
  // {
  //   name: 'Odometer reading',
  //   id: 'ODOmeter',
  //   sort: true,
  //   unitConversion: '(km)',
  // },
  {
    name: 'Pre-fuel fill value',
    id: 'FuelFilledFrom',
    sort: true,
    unitConversion: '(ltrs)',
  },
  {
    name: 'Post-fuel fill value',
    id: 'FuelFilledTo',
    sort: true,
    unitConversion: '(ltrs)',
  },
  {
    name: 'Fuel Qty',
    id: 'TotalFuelFilled',
    sort: true,
    unitConversion: '(ltrs)',
    minWidth: '95px',
  },
  { name: 'Geofence', id: 'GeoFenceName', sort: true },
  {
    name: 'Address',
    id: 'Address',
    style: {
      minWidth: '350px',
      whiteSpace: 'break-spaces',
      fontSize: '12px',
    },
  },
];

const AlertCountDefualtTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'Vin', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
];

const AlertsFilterList = [
  { name: 'Geofence', id: 'GeoFence', sort: true },
  { name: 'Adblue Low', id: 'AdblueLow', sort: true },
  { name: 'Adblue Fault', id: 'AdblueFault', sort: true },
  { name: 'Adblue Zone', id: 'AdblueZone', sort: true },
  { name: 'Fuel Drop', id: 'FuelDrop', sort: true },
  { name: 'Fuel Fill', id: 'FuelFill', sort: true },
  { name: 'Engine Overrun', id: 'EngineOverrun', sort: true },
  { name: 'Stoppage', id: 'Stoppage', sort: true },
  { name: 'Overspeed', id: 'Overspeed', sort: true },
  { name: 'Idling', id: 'Idling', sort: true },
  { name: 'Neutral Driving', id: 'NeutralDriving', sort: true },
];

export const TableHeadName = {
  STOPPAGE: StoppageTblHeadName,
  ADBLUELOW: AdBlueLowTblHeadName,
  ADBLUEFAULT: AdBlueFaultTblHeadName,
  ADBLUEZONE: AdBlueZoneTblHeadName,
  NEUTRALDRIVING: NeutralDriveTblHeadName,
  NEUTRALDRIVINGVW: NeutralDriveVWTblHeadName,
  ENGINEOVERRUN: OverrunTblHeadName,
  ENGINEOVERRUNVW: OverrunVWTblHeadName,
  IDLING: IdlingTblHeadName,
  OVERSPEED: OverSpeedTblHeadName,
  OVERSPEEDVW: OverSpeedVWTblHeadName,
  FUELDROP: FuelDropTblHeadName,
  GEOFENCE: GeofenceTblHeadName,
  FUELFILL: FuelFillTblHeadName,
  ALERTCOUNTDEFAULT: AlertCountDefualtTblHeadName,
  ALERTFILTERLIST: AlertsFilterList,
};

export const alertSettingStatus = {
  overspeedAlert: 'OVERSPEED',
  neutralDrivingAlert: 'NEUTRALDRIVING',
  idlingAlert: 'IDLING',
  stoppageAlert: 'STOPPAGE',
  fuelFillAlert: 'FUELFILL',
  fuelDropAlert: 'FUELDROP',
  fuelLowAlert: 'FUELLOW',
  adblueLowAlert: 'ADBLUELOW',
  adblueFaultAlert: 'ADBLUEFAULT',
  adblueZoneAlert: 'ADBLUEZONE',
  engineOverrunAlert: 'ENGINEOVERRUN',
  geofenceAlert: 'GEOFENCEINOUT',
};

export const filterEnableAlerts = data => {
  let output = [];
  if (data) {
    output = Object.keys(data)
      .filter(key => data[key]?.flag)
      .map(key => alertSettingStatus[key]);
  }

  return output;
};
