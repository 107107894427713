/* eslint-disable  */
import Env from '../Helpers/env';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';
export const ALLVEHICLES = 'all';

// Default state
export const DEFAULT_STATE = {
  isFetching: true,
  isError: false,
  response: {},
};

// default state for Fetching false & Error False
export const DEFAULT_STATE_FF_EF = {
  isFetching: false,
  isError: false,
  response: {},
};

// default state for Fetching false & Error true
export const DEFAULT_STATE_FF_ET = {
  isFetching: false,
  isError: true,
  response: {},
};

// default state for Fetching false & Error False
export const DEFAULT_STATE_FF_EF_WEEKLY = {
  isFetchingWeekly: false,
  isError: false,
  response: {},
};

// default state for Fetching false & Error False
export const DEFAULT_STATE_FF_EF_MONTHLY = {
  isFetchingMonthly: false,
  isError: false,
  response: {},
};

// default state for Fetching false & Error False
export const DEFAULT_STATE_FF_EF_YEARLY = {
  isFetchingYearly: false,
  isError: false,
  response: {},
};

export const hrs = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
];

export const w3wStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#19212c',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#746855',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#242f3e',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#2772b0',
      },
    ],
  },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'geometry',
    stylers: [
      {
        color: '#611037',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#06360b',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#06360b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    visibility: 'simplified',
    stylers: [{ color: '#080808' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    visibility: 'simplified',
    stylers: [{ color: '#6e3703' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#6e3703' }],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [{ color: '#6e3703' }],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#6e3703' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#806b63' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#02193b',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#746855',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#746855',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels',
    stylers: [{ visibility: 'on' }],
  },
];

export const tripDetails = [
  { text: 'Early', number: 0, color: '#09d0eb' },
  { text: 'Ontime', number: 0, color: '#8b09dc' },
  { text: 'Delayed', number: 0, color: '#d669fd' },
];

export const toggleButtons = [
  { text: 'Route Play', idx: 0 },
  { text: 'Alerts', idx: 1 },
];

export const chartArray = [
  {
    name: '',
    data: [
      {
        name: '',
        value: null,
      },
    ],
    color: '#cfe9fc',
  },
  {
    name: '',
    data: [
      {
        name: '',
        value: null,
      },
    ],
    color: '#fc7f7f',
  },
  {
    name: '',
    data: [
      {
        name: '',
        value: null,
      },
    ],
    color: '#5ec957',
  },
];

export const alertTypes = [
  { name: 'Vehicle Idle', value: 'idleEmail', smsValue: 'idleSms' },
  {
    name: 'Vehicle Stoppage',
    value: 'stoppageEmail',
    smsValue: 'stoppageSms',
  },
  { name: 'Fuel Drop', value: 'fuelDropEmail', smsValue: 'fuelDropSms' },
  {
    name: 'Geofence In',
    value: 'geofenceInEmail',
    smsValue: 'geofenceInSms',
  },
  {
    name: 'Geofence Out',
    value: 'geofenceOutEmail',
    smsValue: 'geofenceOutSms',
  },
  { name: 'Over Speed', value: 'overSpeedEmail', smsValue: 'overSpeedSms' },
  {
    name: 'Neutral Driving',
    value: 'neutralDrivingEmail',
    smsValue: 'neutralDrivingSms',
  },
  { name: 'DTC', value: 'dtcEmail', smsValue: 'dtcSms' },
  { name: 'AdBlue BSVI', value: 'adBlueEmail', smsValue: 'adblueSms' },
];

export const tabs = [
  { value: 'Geofence IN', tabIndex: 129 },
  { value: 'Geofence OUT', tabIndex: 130 },
  { value: 'Vehicle Stoppage', tabIndex: 121 },
  { value: 'Overspeed', tabIndex: 125 },
  { value: 'Vehicle Idle', tabIndex: 122 },
  { value: 'Fuel Drop', tabIndex: 120 },
  { value: 'Engine OverRunning', tabIndex: 124 },
  { value: 'Neutral Running Alert', tabIndex: 123 },
  { value: 'Adblue Fault', tabIndex: 126 },
  { value: 'Adblue Zone', tabIndex: 127 },
  { value: 'Adblue Low Level', tabIndex: 128 },
];

export const serviceDivs = [
  { name: ' serviceDivs redDiv', title: 'Service Now' },
  { name: ' serviceDivs orangeDiv', title: 'Service Later' },
  { name: 'serviceDivs grayDiv', title: 'Service Info' },
];

export const handleAlertReportType = val => {
  let alertType;
  switch (val) {
    case 'Geofence IN':
      return (alertType = 129);

    case 'Geofence OUT':
      return (alertType = 130);
    case 'Overspeed':
      return (alertType = 125);
    case 'Vehicle Idle':
      return (alertType = 122);
    case 'Fuel Drop':
      return (alertType = 120);
    case 'Engine OverRunning':
      return (alertType = 124);
    case 'Neutral Running Alert':
      return (alertType = 123);
    case 'Adblue Low Level':
      return (alertType = 128);

    case 'Adblue Zone':
      return (alertType = 127);

    case 'Adblue Fault':
      return (alertType = 126);

    case 'Vehicle Stoppage':
      return (alertType = 121);

    default:
      break;
  }

  return alertType;
};

export const overSpeedList = [
  { speedLimit: 60, speeds: '60 km / hr' },
  { speedLimit: 80, speeds: '80 km / hr' },
  { speedLimit: 100, speeds: '100 km / hr' },
];

export const TableHead = ['registrationId', 'ALERT_NAME', 'IST_TIME', 'latlng'];
export const TableHeadNeutral = [
  'registrationId',
  'ALERT_NAME',
  'IST_TIME',
  'latlng',
];
export const TableHeadName = [
  'Registration No',
  'Alert Type',
  'Date and Time(MM /DD /YY HH:MM)',
  'Location',
];

export const ProfileTableHead = [
  'Name',
  'userName',
  'mobileNumber',
  'addressLine1',
  'cityName',
  'emailId',
];

export const ProfileTableHeadName = [
  'NAME',
  'USERNAME',
  'MOBILE NUMBER',
  'ADDRESS',
  'CITY',
  'EMAIL',
];

export const CustomerTableHead = [
  'userName',
  'firstName',
  'mobileNumber',
  'emailId',
  'countryName',
  'stateName',
  'parentCRN',
];

export const CustomerTableHeadName = [
  'USERNAME',
  'FIRSTNAME',
  'MOBILE NUMBER',
  'EMAIL ADDRESS',
  'COUNTRY',
  'STATE',
  'PARENT CRN',
];

export const SEARCH_VEHICLES = 'SEARCH';

export const CLEAR_VEHICLES = 'CLEAR';

export const mapStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#19212c',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#746855',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#242f3e',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#2772b0',
      },
    ],
  },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'geometry',
    stylers: [
      {
        color: '#611037',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#06360b',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#06360b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    visibility: 'simplified',
    stylers: [{ color: '#080808' }],
  },

  {
    featureType: 'road.highway',
    elementType: 'geometry',
    visibility: 'simplified',
    stylers: [{ color: '#6e3703' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#6e3703' }],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [{ color: '#6e3703' }],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#6e3703' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#806b63' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#02193b',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#746855',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#746855',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels',
    stylers: [{ visibility: 'on' }],
  },
];

export const calculateDelay = val => {
  let delay;
  switch (val) {
    case 2:
      return (delay = 6300);

    case 3:
      return (delay = 5000);
    case 4:
      return (delay = 3500);
    case 5:
      return (delay = 2000);
    case 6:
      return (delay = 800);
    case 7:
      return (delay = 200);
    case 8:
      return (delay = 70);
    case 9:
      return (delay = 30);

    case 10:
      return (delay = 1);

    default:
      break;
  }

  return delay;
};

export const MileageInfo =
  'Truck mileage in Km/L based on the distance (ODO) <br/> Fuel consumption calculated based on fuel injected by the pump';
export const AdBlueInfo =
  'Adblue consumed (in litres) for every 100 Litres of fuel consumption';
export const TraceInfo = 'Click to know the whereabouts of selected vehicle(s)';
export const FleetInfo =
  'Overview of all vehicle status. <br/>Running means vehicle is moving.<br/> Idling means Engine is on without vehicle movement.<br/> Stopped means Engine is OFF and Vehicle is in stationary mode.<br/> Keyon means Engine is ON and Vehicle is in stationary mode.<br/> Offline is trucks is Off';
export const PerInfo = 'Current Fuel & Adblue level of tank, ODO and ETA';
export const TruckInfo =
  'Percentage of Engine on and Idling hours in a day in 24 hours';
export const statusInfo =
  'Trip completion status of all trips for the selected period';
export const speedInfo =
  'Percentage of driving hours spent in various speed (kmph) band.<br/> Optimal : 41-60 Kmph.<br/> Not recommended : more than 80 Kmph.<br/> Normal : all other.<br/>';
export const RPMInfo =
  'Percentage of driving hours spent in various engine speed (RPM) band.<br/> Optimal : 1201-1600 RPM.<br/> Not recommended : more than 2500 RPM . <br/>Normal : all other. ';
export const drivingInfo =
  'Based on the driving pattern, we rate the driving based on our own proprietary logic. 100 is Maximum score';
export const insightsInfo = 'Total number of unsafe events to have occurred';
export const serviceInfo = 'Graph description';
export const alertMsg =
  ' For best result in MDT vehicles, truck has to run a minimum of 15Km in a day. If MDT Vehicle stopped for more than 48 hours, then please do not consider the data recorded during the initial hour of operation on that day for better accuracy.';
export const MileageUrl = 'onlinefleetmngmt/dicv/MileageVsDate';
export const DashboardMileageUrl = 'dashboard/tk3/MileageVsDate';
export const AdBlueUrl = 'onlinefleetmngmt/dicv/adbluebydate';
export const DashboardAdBlueUrl = 'dashboard/tk3/adbluebydate';
export const RpmUrl = 'onlinefleetmngmt/dicv/rpmband';
export const SpeedUrl = 'onlinefleetmngmt/dicv/speedband';
export const DashboardRpmUrl = 'dashboard/tk3/rpmband';
export const DashboardSpeedUrl = 'dashboard/tk3/speedband';
export const drivingUrl = 'dashboard/tk3/drivingScore';
export const fleetUrl = 'dashboard/tk3/getFleetStatusFMT';
export const dtcUrl = 'onlinefleetmngmt/dicv/getLiveDtc';
export const statusUrl = 'onlinefleetmngmt/dicv/getTripStatusForDashboard';
export const tripStatusDashUrl = 'dashboard/tk3/getTripStatusForDashboard';
export const insightUrl = 'onlinefleetmngmt/dicv/truckinsight';
export const DashboardInsightUrl = 'dashboard/tk3/truckinsight';
export const DashboardPerformanceUrl = 'dashboard/tk3/getPerformance';
export const performanceUrl = 'onlinefleetmngmt/dicv/getPerformance';
export const utilizationUrl = 'onlinefleetmngmt/dicv/UtilizationVsDate';
export const DashboardUtilizationUrl = 'dashboard/tk3/UtilizationVsDate';
export const serviceUrl = 'onlinefleetmngmt/dicv/servicedone';
export const DashBoardServiceUrl = 'dashboard/tk3/servicedone';
export const dueSoon = 'onlinefleetmngmt/dicv/service-reminder-duesoon';
export const DashboardDueSoon = 'dashboard/tk3/service-reminder-duesoon';
export const overdue = 'onlinefleetmngmt/dicv/service-reminder-overdue';
export const DashboardOverdue = 'dashboard/tk3/service-reminder-overdue';
export const serviceCount = 'onlinefleetmngmt/dicv/service-reminder-count';
export const DashBoardServiceCount = 'dashboard/tk3/service-reminder-count';
export const showAll = 'find-my-truck/showalltrucks';
//export const DashboardShowAll = 'dashboard/tk3/showalltrucks';
export const DashboardShowAllFMT = 'getCurrentDetailsFMT';
export const getVehicleIds = 'onlinefleetmngmt/dicv/getVehicleIds';
export const vehicleData = 'onlinefleetmngmt/dicv/getActiveVehicles?userId=';
export const maintainMsg =
  'We are updating our portal. Kindly contact us in case of any inconvenience.';
export const getGroupUrl = 'onlinefleetmngmt/dicv/getGroups';
export const tickerUrl = 'dicvticker/tk3/messages';
export const manageGroups = 'onlinefleetmngmt/dicv/manageDicvGroups';
export const getRegionsUrl = 'onlinefleetmngmt/dicv/getAllRegions';
export const manageRegions = 'onlinefleetmngmt/dicv/manageDicvRegions';
export const getPreferences = 'onlinefleetmngmt/dicv/getUserAlertPreference';
export const setPreferences = 'onlinefleetmngmt/dicv/setUserAlertPreference';
export const resetPwdUrl = 'onlinefleetmngmt/dicv/changePassword';
export const getKpi = 'onlinefleetmngmt/dicv/kpi/scalingFactor';
export const setKpi = 'onlinefleetmngmt/dicv/kpi/scalingFactor';
export const getAlertSettingUrl = 'onlinefleetmngmt/dicv/alert/configuration';
export const setAlertUrl = 'onlinefleetmngmt/dicv/alert/configuration';
export const setMaintainanceUrl = 'onlinefleetmngmt/dicv/enablemaintenance';
export const getMaintainanceUrl = 'onlinefleetmngmt/dicv/maintenancestatus';
export const userManualUrl = 'onlinefleetmngmt/dicv/user-manual';
export const vehicleSummaryRpt =
  'onlinefleetmngmt/dicv/reports/vehicleSummaryRpt';
export const locationBySpeedUrl =
  'onlinefleetmngmt/dicv/reports/locationBySpeed';
export const locationByTimeUrl = 'onlinefleetmngmt/dicv/reports/locationByTime';
export const vehicleSummaryRptXLS =
  'onlinefleetmngmt/dicv/xls-report/VEHICLE_SUMMARY_REPORT';
export const vehicleSummaryRptPDF =
  'onlinefleetmngmt/dicv/pdf-report/VEHICLE_SUMMARY_REPORT';
export const overSpeedRpt = 'onlinefleetmngmt/dicv/reports/overSpeedRpt';
export const overSpeedRptXLS =
  'onlinefleetmngmt/dicv/xls-report/OVERSPEED_REPORT';
export const overSpeedRptPDF =
  'onlinefleetmngmt/dicv/pdf-report/OVERSPEED_REPORT';
export const fuelRpt = 'onlinefleetmngmt/dicv/reports/fuelConsumptionRpt';
export const fuelRptXLS =
  'onlinefleetmngmt/dicv/xls-report/FUEL_CONSUMPTION_REPORT';
export const fuelRptPDF =
  'onlinefleetmngmt/dicv/pdf-report/FUEL_CONSUMPTION_REPORT';
export const instantRpt = 'onlinefleetmngmt/dicv/reports/vehInstantInfoRpt';
export const instantRptXLS =
  'onlinefleetmngmt/dicv/xls-report/VEHICLE_INSTANT_REPORT';
export const instantRptPDF =
  'onlinefleetmngmt/dicv/pdf-report/VEHICLE_INSTANT_REPORT';
export const alertTypesUrl = 'onlinefleetmngmt/dicv/getAlertType';
export const alertByImeiUrl = 'onlinefleetmngmt/dicv/reports/alertsByImei';
export const alertByImeiXLS = 'onlinefleetmngmt/dicv/xls-report/ALERT_REPORT';
export const alertByImeiPDF = 'onlinefleetmngmt/dicv/pdf-report/ALERT_REPORT';
export const engineRunrpt = 'onlinefleetmngmt/dicv/reports/engineOverrunRpt';
export const engineRunrptXLS =
  'onlinefleetmngmt/dicv/xls-report/ENGINE_OVERRUN_REPORT';
export const engineRunrptPDF =
  'onlinefleetmngmt/dicv/pdf-report/ENGINE_OVERRUN_REPORT';
export const NDRpt = 'onlinefleetmngmt/dicv/reports/vehicleNeutralDriveRpt';
export const NDXLS = 'onlinefleetmngmt/dicv/xls-report/NEUTRAL_DRIVING_REPORT';
export const NDPDF = 'onlinefleetmngmt/dicv/pdf-report/NEUTRAL_DRIVING_REPORT';
export const fleetHealthUrl = 'onlinefleetmngmt/dicv/fleetdtcdata';
export const fleetHealthXLS = 'onlinefleetmngmt/dicv/generatefleetdtcdata/xls';
export const fleetHealthPDF = 'onlinefleetmngmt/dicv/generatefleetdtcdata/pdf';
export const truckSummaryUrl = 'drivinganalysis/gettrucksummary';
export const fuelDropUrl = 'drivinganalysis/getfueldropcount';
export const truckScoreUrl = 'drivinganalysis/gettruckscore';
export const truckInsightUrl = 'drivinganalysis/gettruckinsights';
export const DArpmbandUrl = 'drivinganalysis/getrpmband';
export const DAspeedbandUrl = 'drivinganalysis/getspeedband';
export const DAPDFUrl = 'drivinganalysis/pdf-report';
export const DAXLSUrl = 'drivinganalysis/xls-report';
export const DADTCUrl = 'drivinganalysis/gettruckdtc';
export const tripMngmntXlsUrl =
  'onlinefleetmngmt/dicv/generateXlsReportForTripManagement';
export const tripMngmntPdfUrl =
  'onlinefleetmngmt/dicv/generatePdfReportForTripManagement';
export const getVehiclesUrl = 'onlinefleetmngmt/dicv/getActiveVehicles';
export const tripUpdateUrl = 'onlinefleetmngmt/dicv/getTripUpdate';
export const liveLocationUrl = 'onlinefleetmngmt/dicv/getLiveLocation';
export const tripRecordCntUrl = 'onlinefleetmngmt/dicv/getTripRecordCount';
export const VehiclesInTrip = 'onlinefleetmngmt/dicv/getActiveVehiclesTrip';
export const DriversInTrip = 'onlinefleetmngmt/dicv/getActiveDriversForNewTrip';
export const checkDriverAvailable =
  'onlinefleetmngmt/dicv/checkTruckDriverAvailability';
export const createTrip = 'onlinefleetmngmt/dicv/createNewTrip';
export const endTrip = 'onlinefleetmngmt/dicv/endTripManually';
export const tripDetailsUrl = 'onlinefleetmngmt/dicv/getTripDetail';
export const playBackUrl = 'onlinefleetmngmt/dicv/myFleetPlayback';
export const submitGeofenceUrl = 'geofence/create-update';
export const geoFenceRegionUrl = 'geofence/vehicle-region-groupfilter';
export const deleteGeofenceUrl = 'geofence/delete';
export const liveNotificationUrl = 'alerts/live-notifications';
export const geoFenceEditUrl = 'geofence/geofence-edit-details';
export const geoFenceSearchUrl = 'geofence/search';
export const fleetRegionUrl = 'find-my-truck/regionsgroups';
export const vehicleDetailUrl = 'find-my-truck/vehicledetail';
export const fmtSearch = 'find-my-truck/search';
export const routePlayBackUrl = 'find-my-truck/routeplayback';
export const tripTrackUrl = 'onlinefleetmngmt/dicv/getTripTrack';
export const fmtVehicleUrl = 'find-my-truck/vehicles';
export const saveProfile = 'onlinefleetmngmt/dicv/users';
export const getAdminDetails = 'onlinefleetmngmt/dicv/getUserDetail';
export const getCountries = 'onlinefleetmngmt/dicv/getAllCountry';
export const getProfile = 'onlinefleetmngmt/dicv/getUserDetail';
export const driverProfilePdf =
  'onlinefleetmngmt/dicv/generateUserProfileReport/pdf';
export const driverUrl = 'onlinefleetmngmt/dicv/users';
export const getCustomerByCompanyUrl =
  'onlinefleetmngmt/dicv/getCustomerByCompany';
export const driverProfileXLS = 'onlinefleetmngmt/dicv/generateUserReport';
export const getDriverProfiles = 'onlinefleetmngmt/dicv/getRolesBasedUserList';
//To get Active and inactive user
export const getActiveInactiveProfiles = 'userManagementIAM/tk3/usersIAM';
// To delete Active users
export const getRestoreUsers = 'userManagementIAM/tk3/users';
// To restore the inactive users
export const userRestore = 'userManagementIAM/tk3/userRestore';
export const dealerUrl = 'onlinefleetmngmt/dicv/users';
export const getDealers = 'onlinefleetmngmt/dicv/getRolesBasedUserList';
export const dealerXLS = 'onlinefleetmngmt/dicv/generateUserReport';
export const dealerPdf = 'onlinefleetmngmt/dicv/generateUserProfileReport/pdf';
export const vehiclesUrl = 'onlinefleetmngmt/dicv/vehiclesNew';
export const vehiclesOldUrl = 'onlinefleetmngmt/dicv/vehicles';
export const vehCategoriesUrl = 'onlinefleetmngmt/dicv/mappedVehicleCategories';
export const vehVariantUrl = 'onlinefleetmngmt/dicv/vehiclevariant';
export const getCategories = 'onlinefleetmngmt/dicv/getCategory';
export const getTypesUrl = 'onlinefleetmngmt/dicv/getType';
export const selectedUsers = 'onlinefleetmngmt/dicv/getSelectedUsers';
export const vehicleDataUrl = 'onlinefleetmngmt/dicv/getVehicleDetailNew';
export const CustomerByUserId = 'onlinefleetmngmt/dicv/getCustomerByUserId';
export const companiesUrl = 'onlinefleetmngmt/dicv/getCompanies';
export const vehProfilePdf =
  'onlinefleetmngmt/dicv/generateVehicleProfileReport/pdf';
export const vehProfileXLS = 'onlinefleetmngmt/dicv/generateVehicleReport';
export const vehicleProfileList = 'onlinefleetmngmt/dicv/getAllVehiclesList';
export const customerAdminPdf =
  'onlinefleetmngmt/dicv/generateUserProfileReport/pdf';
export const alertFMTUrl = 'find-my-truck/alertsplayback';
//Fluids graph

export const getVehicleFluidsReport = 'getVehicleFluidsReport';
//CDS API endpoints
export const getSegmentApplicationsUrl = 'customerdatashare/vehicle-segment';
export const getProfileDetails = 'customerdatashare/profile';
export const deleteProfileDetails = 'customerdatashare/delete-profile';
export const groupServiceAPIUrl = 'customerdatashare/group-service';
export const signalDetailAPIUrl = 'customerdatashare/signal';
export const getActiveCdsProfileUrl = 'customerdatashare/active-profile';
export const validateEmailOtp = 'customerdatashare/validateEmailOtp';
export const validateMobileOtp = 'customerdatashare/validateMobileOtp';
export const sendOTPURL = 'customerdatashare/sendOtp';
export const createSubscriptionURL = 'customerdatashare/cdssubscription';

//CDS Admin endpoints
export const getProfileByStatusURL = 'customerdatashare/profile-by-status';
export const profileBackgroundInprogress =
  'customerdatashare/profile-backendinprogress';
export const activateProfile = 'customerdatashare/profile-activate';

// Page Meta Data
export const loginMetaTitle = 'BharatBenz | Truckonnect';
export const dashboardMetaTitle = 'Dashboard | Truckonnect';
export const profileMetaTitle = 'Profile | Truckonnect';
export const FMTMetaTitle = 'Find My Truck | Truckonnect';
export const tripMetaTitle = 'Trip Management | Truckonnect';
export const drivingMetaTitle = 'Driving Analysis | Truckonnect';
export const fleetMetaTitle = 'Fleet Health | Truckonnect';
export const reportsMetaTitle = 'Reports | Truckonnect';
export const settingsMetaTitle = 'Settings | Truckonnect';
export const demoMetaTitle = 'Demo | Truckonnect';
export const alertsMetaTitle = 'Alerts | Truckonnect';
export const trackTraceMetaTitle = 'Track And Trace | Truckonnect';
export const betaAccessMetaTitle = 'Beta Access Control | Truckonnect';

export const NavLinks = [
  {
    path:
      '/owner-suite' /* path is used as id to check which NavItem is active basically */,
    name: 'DASHBOARD',
    css: 'nav_icon',
    icon: 'home-icon',
    key: 1,
  },
  // {
  //   path:
  //     '/dashboard' /* path is used as id to check which NavItem is active basically */,
  //   name: 'DASHBOARD',
  //   css: 'nav_icon',
  //   icon: 'home-icon',
  //   key: 13,
  // },
  {
    path:
      '/my-fleet' /* path is used as id to check which NavItem is active basically */,
    name: 'FIND MY TRUCK',
    css: 'nav_icon',
    icon: 'home2-icon',
    key: 2,
  },
  {
    path:
      '/track-trace' /* path is used as id to check which NavItem is active basically */,
    name: 'TRACK AND TRACE',
    css: 'nav_icon',
    icon: 'home2alt-icon',
    key: 12,
  },
  {
    path:
      '/daily-trip-analysis' /* path is used as id to check which NavItem is active basically */,
    name: 'TRIP MANAGEMENT',
    css: 'nav_icon',
    icon: 'home3-icon',
    key: 3,
  },
  // {
  //   path:
  //     '/trip-management' /* path is used as id to check which NavItem is active basically */,
  //   name: 'TRIP MANAGEMENT',
  //   css: 'nav_icon',
  //   icon: 'home3-icon',
  //   key: 14,
  // },
  {
    path:
      '/fleet-health' /* path is used as id to check which NavItem is active basically */,
    name: 'FLEET HEALTH',
    css: 'nav_icon',
    icon: 'home4-icon',
    key: 4,
  },

  {
    path:
      '/driving-analysis' /* path is used as id to check which NavItem is active basically */,
    name: 'DRIVING ANALYSIS',
    css: 'nav_icon',
    icon: 'home5-icon',
    key: 5,
  },
  {
    path:
      '/driving-analysisv2' /* path is used as id to check which NavItem is active basically */,
    name: 'DRIVING ANALYSIS',
    css: 'nav_icon',
    icon: 'home5alt-icon',
    key: 10,
  },
  {
    path:
      '/alerts' /* path is used as id to check which NavItem is active basically */,
    name: 'ALERTS',
    css: 'nav_icon',
    icon: 'home9-icon',
    key: 9,
  },
  {
    path:
      '/reports' /* path is used as id to check which NavItem is active basically */,
    name: 'REPORTS',
    css: 'nav_icon',
    icon: 'home7-icon',
    key: 7,
  },
  {
    path:
      '/reportsv2' /* path is used as id to check which NavItem is active basically */,
    name: 'REPORTS',
    css: 'nav_icon',
    icon: 'home7alt-icon',
    key: 11,
  },
  {
    path:
      '/settings' /* path is used as id to check which NavItem is active basically */,
    name: 'SETTINGS',
    css: 'nav_icon',
    icon: 'home6-icon',
    key: 6,
  },
  {
    path:
      '/demo' /* path is used as id to check which NavItem is active basically */,
    name: 'DEMO',
    css: 'nav_icon',
    icon: 'home10-icon',
    key: 6,
  },
];

export const SuperAdminNavLinks = [
  {
    path: '/profile',
    name: 'Profile',
    css: 'nav_icon',
    icon: 'home8-icon',
    key: 8,
  },
];

export const AdminNavLinks = [
  {
    path: '/profile',
    name: 'Profile',
    css: 'nav_icon',
    icon: 'home8-icon',
    key: 8,
  },

  // {
  //   path:
  //     '/track-trace' /* path is used as id to check which NavItem is active basically */,
  //   name: 'FIND MY TRUCK',
  //   css: 'nav_icon',
  //   icon: 'home2-icon',
  //   key: 2,
  // },
  {
    path:
      '/settings' /* path is used as id to check which NavItem is active basically */,
    name: 'SETTINGS',
    css: 'nav_icon',
    icon: 'home6-icon',
    key: 6,
  },
  // {
  //   path:
  //     '/demo' /* path is used as id to check which NavItem is active basically */,
  //   name: 'DEMO',
  //   css: 'nav_icon',
  //   icon: 'home10-icon',
  //   key: 6,
  // },
  // {
  //   path:
  //     '/beta-access-control' /* path is used as id to check which NavItem is active basically */,
  //   name: 'BETA ACCESS CONTROL',
  //   css: 'nav_icon',
  //   icon: 'home15-icon',
  //   key: 15,
  // },
];

export const uatNavLinks = [
  {
    path:
      '/owner-suite' /* path is used as id to check which NavItem is active basically */,
    name: 'DASHBOARD',
    css: 'nav_icon',
    icon: 'home-icon',
    key: 1,
  },
  {
    path:
      '/track-trace' /* path is used as id to check which NavItem is active basically */,
    name: 'TRACK AND TRACE',
    css: 'nav_icon',
    icon: 'home2alt-icon',
    key: 12,
  },
  {
    path:
      '/alerts' /* path is used as id to check which NavItem is active basically */,
    name: 'ALERTS',
    css: 'nav_icon',
    icon: 'home9-icon',
    key: 9,
  },
  {
    path:
      '/daily-trip-analysis' /* path is used as id to check which NavItem is active basically */,
    name: 'TRIP MANAGEMENT',
    css: 'nav_icon',
    icon: 'home3-icon',
    key: 3,
  },
  {
    path:
      '/fleet-health' /* path is used as id to check which NavItem is active basically */,
    name: 'FLEET HEALTH',
    css: 'nav_icon',
    icon: 'home4-icon',
    key: 4,
  },
  {
    path:
      '/driving-analysisv2' /* path is used as id to check which NavItem is active basically */,
    name: 'DRIVING ANALYSIS',
    css: 'nav_icon',
    icon: 'home5alt-icon',
    key: 10,
  },
  {
    path:
      '/reportsv2' /* path is used as id to check which NavItem is active basically */,
    name: 'REPORTS',
    css: 'nav_icon',
    icon: 'home7alt-icon',
    key: 11,
  },
  {
    path:
      '/settings' /* path is used as id to check which NavItem is active basically */,
    name: 'SETTINGS',
    css: 'nav_icon',
    icon: 'home6-icon',
    key: 6,
  },
  {
    path:
      '/demo' /* path is used as id to check which NavItem is active basically */,
    name: 'DEMO',
    css: 'nav_icon',
    icon: 'home10-icon',
    key: 16,
  },
];

export const alphaNavLinks = [
  {
    path:
      '/owner-suite' /* path is used as id to check which NavItem is active basically */,
    name: 'DASHBOARD',
    css: 'nav_icon',
    icon: 'home-icon',
    key: 1,
  },
  // {
  //   path:
  //     '/dashboard' /* path is used as id to check which NavItem is active basically */,
  //   name: 'DASHBOARD',
  //   css: 'nav_icon',
  //   icon: 'home-icon',
  //   key: 13,
  // },
  {
    path:
      '/my-fleet' /* path is used as id to check which NavItem is active basically */,
    name: 'FIND MY TRUCK',
    css: 'nav_icon',
    icon: 'home2-icon',
    key: 2,
  },
  {
    path:
      '/daily-trip-analysis' /* path is used as id to check which NavItem is active basically */,
    name: 'TRIP MANAGEMENT',
    css: 'nav_icon',
    icon: 'home3-icon',
    key: 3,
  },
  // {
  //   path:
  //     '/trip-management' /* path is used as id to check which NavItem is active basically */,
  //   name: 'TRIP MANAGEMENT',
  //   css: 'nav_icon',
  //   icon: 'home3-icon',
  //   key: 14,
  // },
  {
    path:
      '/fleet-health' /* path is used as id to check which NavItem is active basically */,
    name: 'FLEET HEALTH',
    css: 'nav_icon',
    icon: 'home4-icon',
    key: 4,
  },

  {
    path:
      '/driving-analysis' /* path is used as id to check which NavItem is active basically */,
    name: 'DRIVING ANALYSIS',
    css: 'nav_icon',
    icon: 'home5-icon',
    key: 5,
  },
  {
    path:
      '/reports' /* path is used as id to check which NavItem is active basically */,
    name: 'REPORTS',
    css: 'nav_icon',
    icon: 'home7-icon',
    key: 7,
  },
  {
    path:
      '/settings' /* path is used as id to check which NavItem is active basically */,
    name: 'SETTINGS',
    css: 'nav_icon',
    icon: 'home6-icon',
    key: 6,
  },
];
