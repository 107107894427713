/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-parens */
import React from 'react';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import { useSelector, useDispatch } from 'react-redux';

const ThemeSwitcher = props => {
  const { themeSelected } = useSelector(state => state.uiState);
  const dispatch = useDispatch();

  const toggleTheme = () => {
    const theme = localStorage.getItem('theme') === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', theme);
    if (props && props.changeLiveTheme) {
      theme === 'light'
        ? props.changeLiveTheme(true)
        : props.changeLiveTheme(false);
    }
    dispatch({ type: 'TOGGLE_THEME', payload: theme });
  };

  return window.location.pathname === '/alerts' ||
    window.location.pathname === '/reportsv2' ||
    window.location.pathname === '/trip-management' ||
    window.location.pathname === '/driving-analysisv2' ||
    window.location.pathname === '/dashboard' ||
    window.location.pathname === '/my-fleet' ||
    window.location.pathname === '/reports' ||
    window.location.pathname === '/track-trace' ||
    window.location.pathname === '/owner-suite' ||
    window.location.pathname === '/driving-analysis' ||
    window.location.pathname === '/settings' ||
    window.location.pathname === '/fleet-health' ||
    window.location.pathname === '/daily-trip-analysis' ||
    window.location.pathname === '/liveTrack' ||
    window.location.pathname === '/liveTracking' ||
    window.location.pathname === '/demo' ||
    window.location.pathname === '/settings' ||
    // window.location.pathname === '/beta-access-control' ||
    window.location.pathname === '/support' ||
    window.location.pathname === '/profile' ? (
    <span className="mr-2">
      {themeSelected === 'light' ? (
        <Brightness3Icon
          style={{ cursor: 'pointer', fill: '#141728' }}
          onClick={toggleTheme}
        />
      ) : (
        <WbSunnyIcon style={{ cursor: 'pointer' }} onClick={toggleTheme} />
      )}
    </span>
  ) : null;
};

export default ThemeSwitcher;
