/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Marquee from 'react-fast-marquee';
import Sensors from '../../assets/images/sensors.svg';

const TickerCarousel = props => {
  const doChange = () => {
    props.doChange();
  };

  return (
    <div className="ticker-carousel">
      <Carousel
        animationSpeed={500}
        autoPlay={5000}
        infinite
        stopAutoPlayOnHover
        centered
        value={props.value}
        onChange={doChange}
        dots
      >
        {props.data
          && props.data.length > 0
          && props.data.map(e => (e.messageContent.length > 150 ? (
            <Marquee speed={100}>
              <img src={Sensors} alt="sensors" className="mr-1" />
              <div key={e.messageId} onClick={() => props.openTickerDialog()}>
                {e.messageContent}
              </div>
            </Marquee>
          ) : (
            <>
              <img src={Sensors} alt="sensors" className="mr-1" />
              <div key={e.messageId} onClick={() => props.openTickerDialog()}>
                {e.messageContent}
              </div>
            </>
          )))}
      </Carousel>
    </div>
  );
};

export default TickerCarousel;
