import {
  PENDING,
  FULFILLED,
  REJECTED,
  getGroupUrl,
  manageGroups,
  getRegionsUrl,
  manageRegions,
  getPreferences,
  setPreferences,
  resetPwdUrl,
  getKpi,
  setKpi,
  getMaintainanceUrl,
  setMaintainanceUrl,
  setAlertUrl,
  getAlertSettingUrl,
  getSegmentApplicationsUrl,
  getProfileDetails,
  tickerUrl,
} from '../../Helpers/Constants';

// group actions
export function getGroups(data) {
  return {
    types: [
      `GET_GROUP_${PENDING}`,
      `GET_GROUP_${FULFILLED}`,
      `GET_GROUP_${REJECTED}`,
    ],
    method: 'GET',
    url: getGroupUrl,
    queryParams: {
      userId: data.userId,
      subGroupId: data.subGroupId,
    },
    data,
  };
}

export function addGroups(data) {
  return {
    types: [
      `ADD_GROUP_${PENDING}`,
      `ADD_GROUP_${FULFILLED}`,
      `ADD_GROUP_${REJECTED}`,
    ],
    method: 'POST',
    url: manageGroups,
    payload: {},
    data,
  };
}
// region action
export function getRegions(data) {
  return {
    types: [
      `GET_REGIONS_${PENDING}`,
      `GET_REGIONS_${FULFILLED}`,
      `GET_REGIONS_${REJECTED}`,
    ],
    method: 'GET',
    url: getRegionsUrl,
    queryParams: {
      userId: data.userId,
    },
    data,
  };
}

export function addRegions(data) {
  return {
    types: [
      `ADD_REGIONS_${PENDING}`,
      `ADD_REGIONS_${FULFILLED}`,
      `ADD_REGIONS_${REJECTED}`,
    ],
    method: 'POST',
    url: manageRegions,
    payload: {},
    data,
  };
}

// alert preference
export function getAlertPrefernce(data) {
  return {
    types: [
      `GET_USER_ALERT_PREFERENCE_${PENDING}`,
      `GET_USER_ALERT_PREFERENCE_${FULFILLED}`,
      `GET_USER_ALERT_PREFERENCE_${REJECTED}`,
    ],
    method: 'GET',
    url: getPreferences,
    queryParams: {
      userId: data.userId,
    },
    data,
  };
}

export function addAlertPreference(data) {
  return {
    types: [
      `ADD_USER_ALERT_PREFERENCE_${PENDING}`,
      `ADD_USER_ALERT_PREFERENCE_${FULFILLED}`,
      `ADD_USER_ALERT_PREFERENCE_${REJECTED}`,
    ],
    method: 'POST',
    url: setPreferences,
    payload: {},
    data,
  };
}
// change password

export function changePassword(data) {
  return {
    types: [
      `CHANGE_PASSWORD_${PENDING}`,
      `CHANGE_PASSWORD_${FULFILLED}`,
      `CHANGE_PASSWORD_${REJECTED}`,
    ],
    method: 'POST',
    url: resetPwdUrl,
    payload: {},
    data,
  };
}

export function getScalingFactor(data) {
  return {
    types: [
      `GET_SCALING_FACTOR_${PENDING}`,
      `GET_SCALING_FACTOR_${FULFILLED}`,
      `GET_SCALING_FACTOR_${REJECTED}`,
    ],
    method: 'GET',
    url: getKpi,
    payload: {},
    queryParams: {
      userId: data.userId,
    },
    data,
  };
}
export function addScalingFactor(data) {
  return {
    types: [
      `ADD_SCALING_FACTOR_${PENDING}`,
      `ADD_SCALING_FACTOR_${FULFILLED}`,
      `ADD_SCALING_FACTOR_${REJECTED}`,
    ],
    method: 'POST',
    url: setKpi,
    payload: {},
    data,
  };
}

// alert setting
export function getAlertSettings(data) {
  return {
    types: [
      `GET_ALERT_SETTINGS_${PENDING}`,
      `GET_ALERT_SETTINGS_${FULFILLED}`,
      `GET_ALERT_SETTINGS_${REJECTED}`,
    ],
    method: 'GET',
    url: getAlertSettingUrl,
    payload: {},
    queryParams: {
      userId: data.userId,
    },
    data,
  };
}
export function addAlertSettings(data) {
  return {
    types: [
      `ADD_ALERT_SETTINGS_${PENDING}`,
      `ADD_ALERT_SETTINGS_${FULFILLED}`,
      `ADD_ALERT_SETTINGS_${REJECTED}`,
    ],
    method: 'POST',
    url: setAlertUrl,
    payload: {},
    data,
  };
}

export function setMaintainanceStatus() {
  return {
    types: [
      `SET_MAINTAINANCE_STATUS_${PENDING}`,
      `SET_MAINTAINANCE_STATUS_${FULFILLED}`,
      `SET_MAINTAINANCE_STATUS_${REJECTED}`,
    ],
    method: 'POST',
    url: setMaintainanceUrl,
    payload: {},
  };
}

export function getMaintainanceStatus(data) {
  return {
    types: [
      `GET_MAINTAINANCE_STATUS_${PENDING}`,
      `GET_MAINTAINANCE_STATUS_${FULFILLED}`,
      `GET_MAINTAINANCE_STATUS_${REJECTED}`,
    ],
    method: 'GET',
    url: getMaintainanceUrl,
    payload: {},
    data,
  };
}

// CDS
export function getSegmentApplications(data) {
  return {
    types: [
      `GET_APPLICATION_SEGMENT_${PENDING}`,
      `GET_APPLICATION_SEGMENT_${FULFILLED}`,
      `GET_APPLICATION_SEGMENT_${REJECTED}`,
    ],
    method: 'GET',
    url: getSegmentApplicationsUrl,
    data,
    header: data,
  };
}

export function getProfileDetailsCDS(data) {
  return {
    types: [
      `GET_PROFILE_DETAILS_${PENDING}`,
      `GET_PROFILE_DETAILS_${FULFILLED}`,
      `GET_PROFILE_DETAILS_${REJECTED}`,
    ],
    method: 'GET',
    url: getProfileDetails,
    payload: {},
    data,
  };
}

// Ticker

export function getTickers() {
  return {
    types: [
      `GET_TICKER_${PENDING}`,
      `GET_TICKER_${FULFILLED}`,
      `GET_TICKER_${REJECTED}`,
    ],
    method: 'GET',
    url: tickerUrl,
    payload: {},
  };
}

export function addTickers(data) {
  return {
    types: [
      `ADD_TICKER_${PENDING}`,
      `ADD_TICKER_${FULFILLED}`,
      `ADD_TICKER_${REJECTED}`,
    ],
    method: 'POST',
    url: tickerUrl,
    payload: {},
    data,
  };
}

export function editTickers(data) {
  return {
    types: [
      `EDIT_TICKER_${PENDING}`,
      `EDIT_TICKER_${FULFILLED}`,
      `EDIT_TICKER_${REJECTED}`,
    ],
    method: 'PUT',
    url: `${tickerUrl}/${data.messageId}`,
    payload: {},
    data,
  };
}

export function deleteTickers(id) {
  return {
    types: [
      `DELETE_TICKER_${PENDING}`,
      `DELETE_TICKER_${FULFILLED}`,
      `DELETE_TICKER_${REJECTED}`,
    ],
    method: 'DELETE',
    url: `${tickerUrl}/${id}`,
    payload: {},
  };
}
