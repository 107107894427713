import React from 'react';

function TickerDialog({ closeDialog, tickerList }) {
  const handleOkay = () => {
    closeDialog();
  };

  return (
    <>
      <div className="tickerModalContainer">
        <div className="tickerModal">
          <div className="tick-modal-header">MESSAGES</div>
          <ul>
            {tickerList
              && tickerList.length > 0
              && tickerList.map(ticker => (
                <li
                  key={ticker.messageId}
                  className={ticker.messageCriticality === 1 && 'critical-bold'}
                >
                  {ticker.messageContent}
                </li>
              ))}
          </ul>
          <div className="buttonContainer">
            <div className="okayButton">
              <button
                className="btn ok-trip-btn"
                type="button"
                onClick={() => handleOkay()}
              >
                OKAY
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TickerDialog;
