/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import Env from './Helpers/env';
import Authorization from './Helpers/Authorization';
import Loader from './components/Common/loader';

const SessionManager = ({ stopSessionAlert }) => {
  const alertBeforeExpiryMinutes = Env.env.ALERT_BEFORE_EXPIRY_MINUTES * 1;
  const [conditionMet, setConditionMet] = useState(false);
  const [minutesLeftForExpiry, setMinutesLeftForExpiry] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // condition
  const checkCondition = () => {
    const tokenExpirationTime = localStorage.getItem('expires_on') * 1 * 1000;
    // eslint-disable-next-line max-len
    const alertTime = tokenExpirationTime - alertBeforeExpiryMinutes * 60 * 1000;

    if (Date.now() > alertTime && Date.now() < tokenExpirationTime) {
      setMinutesLeftForExpiry(
        Math.floor((tokenExpirationTime - Date.now()) / 60000)
      );

      return true;
    }

    return false;
  };

  const handleTokenRefresh = data => {
    const auth_user = JSON.parse(localStorage.getItem('auth_user'));
    localStorage.setItem(
      'auth_user',
      JSON.stringify({ ...auth_user, token: `Bearer ${data.access_token}` })
    );
    localStorage.removeItem('expires_on');
    localStorage.setItem('expires_on', data.expires_on);
  };

  const extendSession = () => {
    if (Date.now() > localStorage.getItem('expires_on') * 1 * 1000) {
      Authorization.logout();
      toastr.error('Session Expired. Please login again');

      return;
    }
    const refreshToken = localStorage.getItem('refresh_token');
    const body = {
      client_id: `${Env.env.REACT_APP_IAM_CLIENT_ID}`,
      redirect_uri: `${Env.env.REACT_APP_IAM_REDIRECT_URI}`,
      scope: `${Env.env.REACT_APP_IAM_SCOPE}`,
      grant_type: `${Env.env.REACT_APP_IAM_REFRESH_GRANT_TYPE}`,
      code_verifier: `${Env.env.REACT_APP_IAM_CODE_VERIFIER}`,
      refresh_token: refreshToken,
    };
    setIsLoading(true);
    fetch(`${Env.env.REACT_APP_IAM_BASE_URL}/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(body).toString(), // Convert data to URLSearchParams
    })
      // eslint-disable-next-line consistent-return
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
        setConditionMet(false);
        toastr.error(
          'Session Refresh Failed. Please try again on the next Alert'
        );
      }) // Parse JSON response (if applicable)
      .then(data => {
        handleTokenRefresh(data);
        setConditionMet(false);
        toastr.success('Session Extended Successfully');
        setIsLoading(false);
      })
      .catch(error => {
        toastr.error(
          'Session Refresh Failed. Please try again on the next Alert'
        );
        console.error('Error:', error);
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    setConditionMet(false);
    localStorage.setItem('expiryAcknowledged', true);
    stopSessionAlert();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const condition = checkCondition();
      if (condition) {
        setConditionMet(true);
        // clearInterval(interval); // Stopping the timer if the condition is met
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <>
      {conditionMet
        && (isLoading ? (
          <Loader />
        ) : (
          <div className="endTripContainer">
            <div className="endTripModal">
              <div className="rghtTick" />
              <p className="content">
                {`Your session is going to expire in
                       ${minutesLeftForExpiry} minutes. 
                          Do you want to extend the current session ?`}
              </p>
              <div className="buttonContainer">
                <div className="cancelButton">
                  <button
                    type="button"
                    className="btn ok-trip-btn"
                    onClick={handleClose}
                  >
                    CANCEL
                  </button>
                </div>
                <div className="okayButton">
                  <button
                    className="btn ok-trip-btn"
                    type="button"
                    onClick={extendSession}
                  >
                    OKAY
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default SessionManager;
