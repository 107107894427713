import React from 'react';
import { Route } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import SideBar from '../Common/SideBar';
import { NotAuthroized } from '../NotFound';

const routesPermission = [
  {
    path: '/profiles/dealer',
  },
  {
    path: '/profiles/customer-admin',
  },
];

const Application = ({
  component: Component,
  isLoggedIn,
  auth,
  path,
  sideBarMenu,
  role,
  uiType,
  ...rest
}) => (
  <Route
    {...rest}
    render={() => {
      let auth = true;
      if (role === 'CUSTOMERADMIN') {
        routesPermission.map(val => {
          if (path === val.path) {
            auth = false;
          }

          return '';
        });
      }
      // eslint-disable-next-line no-console

      return (
        <div>
          {auth ? (
            <>
              {isLoggedIn() && <Header />}
              {isLoggedIn() && path !== '/cssupport' && (
                <SideBar uiType={uiType} />
              )}
              <Component
                path={role === 'CSSUPPORT' ? '/cssupport' : '/track-trace'}
                {...rest}
              />
            </>
          ) : (
            <NotAuthroized default />
          )}

          {isLoggedIn() && <Footer />}
        </div>
      );
    }}
  />
);

export default Application;
